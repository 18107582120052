<template>
  <div>
    <div class="slide">
      <div class="bg-img">
        <div class="slide-content">
          <div>
            <h2 class="slide-header"> our Employees</h2>
          </div>
          <div>
            <h5 class="slide-h5">Home</h5>
            <b-icon :icon="isRTL ? 'chevron-double-left' : 'chevron-double-right'" class="icon-slider"></b-icon>
            <h5 class="slide-h5"> Employees</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="container custom-container-orders mt-5">
      <b-row class="align-items-center">
        <b-col cols="4" class="d-flex align-items-center">
          <h3 class="filter-text">Filter</h3>
          <b-form-input class="filter-input" id="filter-input" v-model="filter"
            placeholder="Admin Number"></b-form-input>

        </b-col>
        <b-col cols="8" class="text-right checkFilter">
          <!-- Checkbox for filtering admins -->
          <div class="checkox-btn">
            <b-form-checkbox v-model="isAdminsOnly" class="ml-5" @change="fetchAdmins">
              <h3 class="filter-text">Admins</h3>
            </b-form-checkbox>
          </div>
          <div class="checkox-btn">
            <b-form-checkbox v-model="blockList" class="mr-5 ml-3" @change="fetchlockList">
              <h3 class="filter-text">Block list</h3>
            </b-form-checkbox>
          </div>
          <b-button variant="warning" @click="addEmployeeModal()" class="new-order">Add New Admin</b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col v-for="employee in filteredRequests" :key="employee.id" cols="12" lg="3" md="4" sm="12"
          class="mb-3 mt-5 card-col">
          <b-card class="employee-card" @click="showEmployeeModal(employee)">
            <div class="employee-image">
              <img v-if="employee.image" :src="employee.image" alt="Employee Image">
              <img v-else :src="require('../assets/victor.png')" alt="Employee Photo">
            </div>
            <b-card-text>
              <p class="employee-name">{{ employee.name }}</p>
              <p class="employee-role">{{ employee.phone }}</p>
            </b-card-text>
          </b-card>
        </b-col>
        <template v-if="filteredRequests.length === 0">
          <div class="text-center p-4" style="width: 100%;">
            <!-- <lord-icon src="https://cdn.lordicon.com/wkppeqfv.json"
                    colors="primary:#121331,secondary:#edd100" trigger="loop" style="width:100px;height:100px">
                </lord-icon> -->
            <img :src="require('../assets/not-found.svg')" alt="404 Photo" style="width:500px;">
            <p class="mb-0 filter-result"> {{ $t('message.noMatchingResults') }} "{{ filter }}".</p>
          </div>
        </template>
      </b-row>
      <!-- Employee Modal -->
      <b-modal id="employee-modal" scrollable v-model="showModal" size="md">
        <!-- <div class="modal-content"> -->
        <template #modal-title>
          <div class="modal-headerr">
            <div class="modal-image">
              <img :src="selectedEmployee.image || require('../assets/victor.png')" alt="Employee Image" />
            </div>
            <div class="header-data">
              <h4>{{ selectedEmployee.name }}</h4>
              <p>{{ selectedEmployee.serviceName }}</p>
            </div>
          </div>
        </template>

        <div class="modal-body">
          <div class="form-group">
            <label for="name"> <b-icon icon="person" aria-hidden="true"></b-icon> User Name</label>
            <input type="text" class="form-control" id="exampleInputEmail1" v-model="selectedEmployee.name" readonly>
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1"> <b-icon icon="voicemail" aria-hidden="true"></b-icon> Email address</label>
            <input type="email" class="form-control" id="exampleInputEmail1" v-model="selectedEmployee.email" readonly>
          </div>

          <div class="form-group">
            <label for="Phone Number"> <b-icon icon="telephone" aria-hidden="true"></b-icon> Phone Number</label>
            <input type="number" class="form-control" v-model="selectedEmployee.phone" readonly>
          </div>

          <div class="form-group">
            <label for="Password"> <b-icon icon="lock" aria-hidden="true"></b-icon> Password</label>
            <input type="password" class="form-control" v-model="selectedEmployee.password" readonly>
          </div>

          <div class="form-group" v-if="!this.isAdminsOnly">
            <label for="Service"> <b-icon icon="box-arrow-right" aria-hidden="true"></b-icon> Service</label>
            <input class="form-control" v-model="selectedEmployee.serviceName" readonly>
          </div>

          <div class="form-group">
            <label for="work_status"> <b-icon icon="box-arrow-right" aria-hidden="true"></b-icon> Custody</label>
            <!-- <input class="form-control" v-model="selectedEmployee.custody"> -->
            <button @click="openCustodyModal()" style="text-align: left;" class="form-control"> {{
              selectedEmployee.custody
            }}</button>
          </div>

          <div class="form-group" v-if="this.blockList === false">
            <label for="block"> <b-icon icon="exclamation-circle" aria-hidden="true"></b-icon> Block</label>
            <button @click="openBlockModal()" style="text-align: left;" class="form-control"> You will block this account
            </button>
          </div>
          <div class="form-group" v-if="this.blockList === true">
            <label for="active"> <b-icon icon="exclamation-circle" aria-hidden="true"></b-icon> active</label>
            <button @click="openBlockModal()" style="text-align: left;" class="form-control"> You will active this account
            </button>
          </div>
        </div>

        <template #modal-footer>
          <div class="w-100">
            <b-button variant="primary" size="md" class="float-right modalFooter" @click="showModal = false">
              Close
            </b-button>
          </div>
        </template>
        <!-- <div class="modal-footer">
          <b-button variant="secondary" @click="showModal = false">Back</b-button>
        </div> -->
        <!-- </div> -->
      </b-modal>


      <b-modal id="add-employee-modal" scrollable v-model="showAddModal" title="Add New Employee" size="md">

        <div class="modal-body">
          <div class="form-group">
            <label for="name"> <b-icon icon="person" aria-hidden="true"></b-icon> User Name</label>

            <input type="text" class="form-control" placeholder="Enter your name" v-model="form.name">
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1"> <b-icon icon="voicemail" aria-hidden="true"></b-icon> Email address</label>
            <input type="email" class="form-control" placeholder="Enter your email" v-model="form.email">
          </div>

          <div class="form-group">
            <label for="Phone Number"> <b-icon icon="telephone" aria-hidden="true"></b-icon> Phone Number</label>
            <input type="number" class="form-control" placeholder="Enter your phone" v-model="form.phone">
          </div>

          <div class="form-group">
            <label for="Password"> <b-icon icon="lock" aria-hidden="true"></b-icon> Password</label>
            <input type="password" class="form-control" placeholder="Enter your password" v-model="form.password">
          </div>
          <div class="form-group">
            <label for="Service"> <b-icon icon="box-arrow-right" aria-hidden="true"></b-icon> Service</label>
            <v-select placeholder="Enter your service" :disabled="form.type === 'Admin'" @input="fetchTechniciansData"
              v-model="form.service" :options="services" label="name" />
          </div>




          <div class="form-group">
            <label for="Employee-type"> <b-icon icon="box-arrow-right" aria-hidden="true"></b-icon> Employee
              type</label>
            <v-select :dir="direction" v-model="form.type" :options="employeesType" placeholder="Enter your type">
            </v-select>
          </div>
        </div>

        <template #modal-footer>
          <div class="w-100">
            <b-button size="md" class="float-right modalFooter-close" @click="showAddModal = false">
              Close
            </b-button>
            <b-button size="md" class="float-right modalFooter-add" :disabled="!isFormValid" @click="addNewEmployee()">
              Add New Employee
            </b-button>
          </div>
        </template>
        <!-- <div class="modal-footer">
          <b-button variant="secondary" @click="showModal = false">Back</b-button>
        </div> -->
        <!-- </div> -->
      </b-modal>

      <b-modal id="edit-custody" scrollable v-model="custodyModal" title="Edit Custody" size="sm">
        <div class="modal-body">
          <div class="form-group">
            <label for="custody">
              <b-icon icon="box-arrow-right" aria-hidden="true"></b-icon> Custody
            </label>
            <input class="form-control" v-model="selectedEmployee.custody" />
          </div>
        </div>

        <template #modal-footer>
          <div class="w-100">
            <b-button size="md" class="float-right modalFooter-close" @click="custodyModal = false">
              Close
            </b-button>
            <b-button size="md" class="float-right modalFooter-add" @click="editCustody(selectedEmployee)">
              Save
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="edit-custody" scrollable v-model="blockModal" :title="this.blockList === false ? 'Block' : 'Active' " size="sm">
        <div class="modal-body">
          <p v-if="this.blockList === false" class="block-text">Are you sure to block this account?</p>
          <p v-if="this.blockList === true" class="block-text">Are you sure to active this account?</p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button size="md" class="float-right modalFooter-close" @click="blockModal = false">
              Close
            </b-button>
            <div>
            <b-button v-if="blockList === false"  size="md" class="float-right modalFooter-add" @click="BlockUser(selectedEmployee)">
              Block
            </b-button>
            <b-button v-if="blockList === true"  size="md" class="float-right modalFooter-add" @click="BlockUser(selectedEmployee)">
              Active
            </b-button>
          </div>
            
          </div>
        </template>
      </b-modal>

      <div class="mt-5 pagination">
        <b-pagination v-model="currentPage" pills :total-rows="rows" :per-page="perPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../axios-config';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { mapGetters } from 'vuex'

export default {
  name: "about",
  components: {
    vSelect
  },
  data() {
    return {
      showModal: false,
      showAddModal: false,
      custodyModal: false,
      blockModal: false,
      employeesType: [
        'Technican',
        'Admin',
      ],
      selectedEmployee: {
        name: '',
        image: '',
        email: '',
        phone: '',
        password: '',
        service: '',
        orders: '',
      },
      form: {
        name: '',
        email: '',
        phone: '',
        password: '',
        service: '',
        type: '',
      },
      filter: '',
      isAdminsOnly: false,
      blockList: false,
      employees: [],
      services: [],
      rows: 0,
      currentPage: 1,
      perPage: 8,
    };

  },
  computed: {
    ...mapGetters(['getUser']),
    company_id() {
      return this.getUser; // استخدام الـ getter لجلب بيانات المستخدم
    },
    filteredRequests() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;

      // Filter by both name and phone
      const filtered = this.employees.filter(order =>
        order.phone.toLowerCase().includes(this.filter.toLowerCase()) ||
        order.name.toLowerCase().includes(this.filter.toLowerCase())
      );

      this.rows = filtered.length; // Update the total rows after filtering
      return filtered.slice(start, end);
    },

    isRTL() {
      return this.$i18n.locale === 'ar';
    },
    isFormValid() {
      // Check if all required fields are filled
      const { name, email, phone, password, service, type } = this.form;
      const isServiceRequired = type !== 'Admin'; // Service is required if type is not 'Admin'
      const isFilled = name && email && phone && password && type;
      const isServiceValid = isServiceRequired ? !!service : true;

      return isFilled && isServiceValid;
    },
  },
  created() {
    this.fetchAdmins();
  },
  methods: {

    fetchAdmins() {
      if (this.isAdminsOnly) {
        // Send a request to fetch all admins
        this.fetchAllAdmins();
      } else {
        // Handle unchecking the checkbox (optional)
        this.fetchData(); // Or any other default fetch method
      }
    },
    fetchlockList() {
      if (this.isAdminsOnly) {
        // Send a request to fetch all admins
        this.fetchAllAdmins();
      } else {
        // Handle unchecking the checkbox (optional)
        this.fetchData(); // Or any other default fetch method
      }
    },

    async fetchData() {
      try {
        const formData = new FormData();
        formData.append('offset', 0);
        formData.append('company_id', this.company_id.id);
        if (this.blockList === true) {
          formData.append('account_status', 1);
        }else{
          formData.append('account_status', 0);
        }
        const response = await axiosInstance.post('select_employees_company.php', formData);

        if (response.data.status === "true") {
          this.employees = [];
          this.employees = response.data.output;
          console.log('employees = ', this.employees);

        }
      } catch (error) {
        console.error('Error logging in:', error)
        this.$bvToast.toast('An error occurred while trying to get data', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left', // Position of the toast
          // autoHideDelay: 1000,
        });
      }
    },
    async fetchAllAdmins() {
      try {
        const formData = new FormData();
        formData.append('offset', 0);
        formData.append('company_id', this.company_id.id);
        if (this.blockList === true) {
          formData.append('account_status', 1);
        }else{
          formData.append('account_status', 0);
        }
        const response = await axiosInstance.post('company/select_admins.php', formData);

        if (response.data.status === "true") {
          this.employees = [];
          this.employees = response.data.output;
          console.log('employees = ', this.employees);

        }
      } catch (error) {
        console.error('Error logging in:', error)
        this.$bvToast.toast('An error occurred while trying to get data', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left', // Position of the toast
          // autoHideDelay: 1000,
        });
      }
    },

    showEmployeeModal(employee) {
      this.selectedEmployee = employee;
      this.showModal = true;
    },
    async fetchServicesData() {
      try {
        const formData = new FormData();
        formData.append('company_id', this.company_id.id);
        const response = await axiosInstance.post('company/select_services.php', formData);

        console.log(response.data);
        if (response.data.status === "true") {
          this.services = response.data.output;
        }
      } catch (error) {
        console.error('Error logging in:', error)
        this.$bvToast.toast('An error occurred while trying to get data', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left', // Position of the toast
          // autoHideDelay: 1000,
        });
      }
    },
    async addAdmin() {
      try {
        const formData = new FormData();
        formData.append('company_id', this.company_id.id);
        formData.append('name', this.form.name);
        formData.append('email', this.form.email);
        formData.append('phone', this.form.phone);
        formData.append('password', this.form.password);
        // if (form.type === 'Technican') {
        //   formData.append('service_id', this.form.service.id);
        // }
        const response = await axiosInstance.post('company/insert_admin.php', formData);

        console.log(response.data);
        if (response.data.status === "true") {
          this.employees.push(response.data.output);
          this.$bvToast.toast(response.data.message, {
            title: 'Error',
            variant: 'primary',
            solid: true,
            toaster: 'b-toaster-bottom-left', // Position of the toast
            // autoHideDelay: 1000,
          });
          this.showAddModal = false;
        }
      } catch (error) {
        console.error('Error logging in:', error)
        this.$bvToast.toast('An error occurred while trying to get data', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left', // Position of the toast
          // autoHideDelay: 1000,
        });
      }
    },
    async addTechnican() {
      try {
        const formData = new FormData();
        formData.append('company_id', this.company_id.id);
        formData.append('name', this.form.name);
        formData.append('email', this.form.email);
        formData.append('phone', this.form.phone);
        formData.append('password', this.form.password);
        formData.append('service_id', this.form.service.id);

        const response = await axiosInstance.post('company/insert_technical.php', formData);

        console.log(response.data);
        if (response.data.status === "true") {
          this.employees.push(response.data.output);
          this.$bvToast.toast(response.data.message, {
            title: 'Error',
            variant: 'primary',
            solid: true,
            toaster: 'b-toaster-bottom-left', // Position of the toast
            // autoHideDelay: 1000,
          });
          this.showAddModal = false;
        }
      } catch (error) {
        console.error('Error logging in:', error)
        this.$bvToast.toast('An error occurred while trying to get data', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left', // Position of the toast
          // autoHideDelay: 1000,
        });
      }
    },
    addEmployeeModal() {
      this.showAddModal = true;
      this.fetchServicesData();
    },
    addNewEmployee() {
      if (this.form.type === 'Admin') {
        this.addAdmin();
      } else {
        this.addTechnican();
      }
    },
    async editCustody(selectedItem) {
      try {
        const formData = new FormData();
        formData.append('id', selectedItem.id);
        formData.append('custody', selectedItem.custody);

        let response; // تعريف الـ response هنا مرة واحدة

        if (!this.isAdminsOnly) {
          formData.append('admin_id', 0);
          response = await axiosInstance.post('company/update_custody_technical.php', formData);
        } else {
          response = await axiosInstance.post('company/update_custody_admin.php', formData);
        }

        console.log(response.data);
        if (response.data.status === "true") {
          this.$bvToast.toast(response.data.message, {
            title: 'Success', // تأكد من العنوان المناسب
            variant: 'primary',
            solid: true,
            toaster: 'b-toaster-bottom-left', // تحديد مكان الـ toast
          });
          this.custodyModal = false; // غلق المودال بعد التعديل
          this.showModal = false;
          window.location.reload();

        } else {
          this.$bvToast.toast('An error occurred while trying to update custody', {
            title: 'Error',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-bottom-left', // تحديد مكان الـ toast
          });
        }
      } catch (error) {
        console.error('Error updating custody:', error);
        this.$bvToast.toast('An error occurred while trying to get data', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left',
        });
      }
    },
    async BlockUser(selectedItem) {
      try {
        const formData = new FormData();
        formData.append('id', selectedItem.id);
        if (this.blockList === false) {
          formData.append('account_status', 1);
        }else{
          formData.append('account_status', 0);
        }

        let response;

        if (!this.isAdminsOnly) {
          response = await axiosInstance.post('company/update_activation_account_technical.php', formData);
        } else {
          response = await axiosInstance.post('company/update_account_status_admin.php', formData);
        }

        console.log(response.data);
        if (response.data.status === "true") {
          this.$bvToast.toast(response.data.message, {
            title: 'Success', // تأكد من العنوان المناسب
            variant: 'primary',
            solid: true,
            toaster: 'b-toaster-bottom-left', // تحديد مكان الـ toast
          });
          this.blockModal = false; // غلق المودال بعد التعديل
          this.showModal = false;
          window.location.reload();

        } else {
          this.$bvToast.toast('An error occurred while trying to update custody', {
            title: 'Error',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-bottom-left', // تحديد مكان الـ toast
          });
        }
      } catch (error) {
        console.error('Error updating custody:', error);
        this.$bvToast.toast('An error occurred while trying to get data', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left',
        });
      }
    },

    openCustodyModal() {
      this.custodyModal = true; // Open the modal when the button is clicked
    },
    openBlockModal(){
      this.blockModal = true;
    }
  }
}

</script>

<style scoped>
.b-pagination-pills .page-item .page-link {
  line-height: 0.8;
}

.new-order {
  background-image: linear-gradient(90deg, #FFCA1F 0%, #FFD44A 100%);
  border-radius: 20px;
  color: #fff;
  width: 200px;
  font-size: 21px;
  font-family: 'Somar-bold';
  border-width: 0px;
}

.new-order:hover {
  outline: none;
  border-width: 0px;
  background-image: linear-gradient(90deg, #FFD44A 0%, #FFCA1F 100%);
  transition: all 0.3s ease-in-out;
  color: #fff;
}

.employee-card {
  text-align: center;
  border-radius: 20px;
  background-color: #fff;
  width: 220px;
  height: 240px;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  cursor: pointer;
}

.employee-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 5px;
}

.employee-name {
  font-family: 'Somar-Bold';
  color: #45566D;
  font-size: 28px;
  margin-bottom: 0px;
}

.employee-role {
  font-family: 'Somar-Bold';
  color: #2B5FA8;
  font-size: 25px;
  margin-bottom: 0px;
}

.filter-result {
  font-family: 'Somar-Bold';
  color: #2B5FA8;
  font-size: 20px;
}

.card-col {
  text-align: center;
  justify-content: center;
  display: flex;
}

.pagination {
  justify-content: center;
  display: flex;
  align-items: center;
}

/* modal */

.modal-content {
  /* text-align: center; */
}

.modal-headerr {
  display: flex;
  /* align-items: left; */
  justify-content: start !important;
  flex-direction: row;
  align-items: center;
}

.header-data {
  text-align: left;
  margin: 10px;
  /* margin-top: 0px */
  margin-left: 20px;
  margin-top: 15px;
}

.header-data h4 {
  color: #2B5FA8;
  font-family: 'Somar-Bold';
  margin-bottom: 0px;
}

.header-data p {
  color: #7F98BB;
  font-family: 'Somar-Bold';
}

.modal-image img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.modal-footer {
  text-align: right;
}

#add-employee-modal___BV_modal_content_ .form-control:disabled,
#employee-modal___BV_modal_content_ .form-control:disabled,
#employee-modal___BV_modal_content_ .form-control[readonly],
#add-employee-modal___BV_modal_content_ .form-control[readonly],
#employee-modal___BV_modal_content_ .form-control,
#add-employee-modal___BV_modal_content_ .form-control,
#edit-custody___BV_modal_content_ .form-control {
  background: transparent;
  border-width: 0px;
  border-bottom: 2px solid #D1E4FF;
  color: #7F98BB;
  font-family: 'Somar-Medium';
  font-size: 20px;

}

.b-icon.bi {
  color: #FFD95F;
  margin-right: 2px;
  font-size: medium;
}

.form-control:focus {
  box-shadow: none !important;
}

label {
  color: #45566D;
  font-family: 'Somar-Bold';
  font-size: 20px;
  margin-left: -10px;
}

.modalFooter-add {
  border-radius: 10px !important;
  background-image: linear-gradient(90deg, #FFCA1F 0%, #FFD44A 100%);
  color: #fff;
  border: none;
  outline: none;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 20px;
}

.modalFooter-close {
  border-radius: 10px !important;
  background-color: gray !important;
  border: none;
  outline: none;
  font-size: 20px;

}

.filter-input {
  font-size: 20px;
}

.checkFilter {
  justify-content: flex-end;
  display: flex;
}

.checkox-btn {
  margin-top: 5px;
}
.block-text{
  background: transparent;
  color: #7F98BB;
  font-family: 'Somar-Medium';
  font-size: 20px;
}

@media (max-width: 576px) {
  .new-order {

    width: 130px;
    font-size: 18px;

  }
}
</style>