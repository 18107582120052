<template>
  <div class="container custom-container mt-5">
    <h2 class="text-center request-header"><span>Our</span> Requests</h2>
    <b-row class="align-items-center">
      <b-col cols="6" class="d-flex align-items-center">
        <h3 class="filter-text">Filter</h3>
        <b-form-input
          class="filter-input"
          id="filter-input"
          v-model="filter"
          placeholder="Admin Name"
        ></b-form-input>
      </b-col>
      <b-col cols="6" class="text-right">
        <b-button variant="warning" @click="goToRequestPage()" class="request-button">More</b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        v-for="request in filteredRequests"
        :key="request.number"
        cols="12" md="6" class="mb-3 mt-5"
      >
        <b-card class="request-card">
          <b-card-text>
            <div class="request-info-row">
              <span><strong>Request Number:</strong> <div class="request-response"><span> {{ request.number }} </span></div></span>
              <span class="date-time"><b-icon class="clock-icon" icon="clock" aria-hidden="true"></b-icon>{{ request.date }} {{ request.time }}</span>
            </div>
            <div style="">
            <p class="request-info"><strong>Request Title:</strong><div class="request-response"><span> {{ request.title }} </span></div></p>
          </div>
          <div style="line-height: 0px;">
            <p class="request-info"><strong>Added By:</strong> <div class="request-response"> <span> {{ request.addedBy }}</span></div></p>
          </div>
          </b-card-text>
          <template #footer>
            <!-- <b-button variant="info">Show Details</b-button> -->
            <p>Show Details</p>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: '',
      requests: [
        {
          number: '125/7832',
          date: '2023/01/02',
          time: '12:00 pm',
          title: 'Add & Equipment',
          addedBy: 'Admin Name',
        },
        {
          number: '125/7833',
          date: '2023/01/02',
          time: '12:00 pm',
          title: 'Remove Equipment',
          addedBy: 'Admin Name',
        },
        // يمكنك إضافة المزيد من الطلبات هنا
      ],
    };
  },
  computed: {
    filteredRequests() {
      return this.requests.filter(request =>
        request.title.toLowerCase().includes(this.filter.toLowerCase())
      );
    },
  },
  created(){
    this.fetchData();
  },
  methods: {
    
    //   async fetchData() {
    //   try {
    //     const formData = new FormData();
    //     formData.append('current_date', '2023-12-14');
    //     formData.append('days', 8);
    //     formData.append('company_id', 1);
    //     const response = await axiosInstance.post('company/select_pm_orders.php', formData);

    //     console.log(response.data);
    //     if (response.data.status === "true") {
    //       const requests = response.data.output;
    //     }
    //   } catch (error) {
    //     console.error('Error logging in:', error)
    //     this.$bvToast.toast('An error occurred while trying to get data', {
    //       title: 'Error',
    //       variant: 'danger',
    //       solid: true,
    //       toaster: 'b-toaster-bottom-left', // Position of the toast
    //       // autoHideDelay: 1000,
    //     });
    //   }
    // },

      goToRequestPage(){
        this.$nextTick(() => {
            this.$router.push('/Requests');
        });
      }
    }
  
};
</script>

<style scoped>

</style>
