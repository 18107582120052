<template>
    <div class="our-employees-parent">
    <div class="our-employees-section">
    <div class="container">
      <h2 class="text-center employees-header">OUR <span>EMPLOYEES</span></h2>
      <b-row class="align-items-center">
        <b-col cols="6" class="d-flex align-items-center">
            <h3 class="filter-text">Filter</h3>
          <b-form-input
            class="filter-input"
            id="filter-input"
            v-model="filter"
            placeholder="Admin"
          ></b-form-input>
          
        </b-col>
        <b-col cols="6" class="text-right">
          <b-button variant="warning" class="request-button">More</b-button>
        </b-col>
      </b-row>
  
      <b-row>
        <b-col
          v-for="employee in filteredEmployees"
          :key="employee.id"
          cols="12" md="3" sm="12" class="mb-3 mt-5 card-col"
        >
          <b-card class="employee-card">
            <div class="employee-image">
              <img :src= "require('../assets/victor.png')" alt="Employee Photo">
            </div>
            <b-card-text>
              <p class="employee-name">{{ employee.name }}</p>
              <p class="employee-role">{{ employee.role }}</p>
            </b-card-text>
          </b-card>
        </b-col>
        <template v-if="filteredEmployees.length === 0">
            <div class="text-center p-4" style="width: 100%;">
                <!-- <lord-icon src="https://cdn.lordicon.com/wkppeqfv.json"
                    colors="primary:#121331,secondary:#edd100" trigger="loop" style="width:100px;height:100px">
                </lord-icon> -->
                <img :src= "require('../assets/not-found.svg')" alt="404 Photo" style="width:500px;">
                <p class="mb-0 filter-result"> {{ $t('message.noMatchingResults') }} "{{ filter }}".</p>
            </div>
        </template>
      </b-row>
    </div>
    </div>
</div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        filter: '',
        employees: [
          { id: 1, name: 'Ahmed Ali', role: 'Admin', image: '/path-to-image.png' },
          { id: 1, name: 'Ahmed Ali', role: 'Admin', image: '/path-to-image.png' },
          { id: 1, name: 'Ahmed Ali', role: 'Admin', image: '/path-to-image.png' },
          { id: 1, name: 'Ahmed Ali', role: 'Admin', image: '/path-to-image.png' },
          // أضف المزيد من الموظفين هنا
        ],
      };
    },
    computed: {
      filteredEmployees() {
        return this.employees.filter(employee =>
          employee.role.toLowerCase().includes(this.filter.toLowerCase())
        );
      },
    },
  };
  </script>
  
  <style scoped>
  .our-employees-parent{
    margin-top: -100px;
  }
  .our-employees-section {
    background-image: url(../assets/employees2.jpg); 
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 200px;
    padding-top: 400px;
    padding-left: 150px;
    padding-right: 150px;
  }
  
  .employees-header {
    font-family: 'Somar-Bold';
    font-size: 30px;
    color: #2274BD;
    text-transform: uppercase;
  }
  
  .employees-header span {
    color: #FFD448;
  }
  
  .filter-input {
    width: 150px;
    border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  }
  
  .filter-button {
    margin-left: 10px;
    border-radius: 50%;
    background-color: #FFD448;
    border: none;
    color: #fff;
  }

  .filter-text {
  color: #45566D;
  font-family: 'Somar-Bold';
  font-size: 28px;
  margin-right: 10px;
}
  
.request-button {
  background-image: linear-gradient(90deg, #FFCA1F 0%, #FFD44A 100%);
  border-radius: 20px;
  color: #fff;
  width: 100px;
  font-size: 21px;
  font-family: 'Somar-bold';
  border-width: 0px;
}

.request-button:hover {
  outline: none;
  border-width: 0px;
  background-image: linear-gradient(90deg, #FFD44A 0%, #FFCA1F 100%);
  transition: all 0.3s ease-in-out;
  color: #fff;
}
  
  .employee-card {
    text-align: center;
    border-radius: 20px;
    background-color: #fff;
    width: 220px;
    height: 240px;
    /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  }
  
  .employee-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 5px;
  }
  
  .employee-name {
    font-family: 'Somar-Bold';
    color: #45566D;
    font-size: 28px;
    margin-bottom: 0px;
  }
  
  .employee-role {
    font-family: 'Somar-Bold';
    color: #2B5FA8;
    font-size: 25px;
    margin-bottom: 0px;
  }
  .filter-result{
    font-family: 'Somar-Bold';
    color: #2B5FA8;
    font-size: 20px;
  }
@media (max-width: 576px) {
    .our-employees-parent{
        margin-top: 0px;
    }
  .our-employees-section{
    padding-left: 30px;
    padding-right: 30px;
    background-position: center top;
  }

  .card-col{
    text-align: center;
    justify-content: center;
    display: flex;
  }
}
  </style>
  