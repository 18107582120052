<template>
    <div>
        <div class="slide">
            <div class="bg-img">
                <div class="slide-content">
                    <div><h2 class="slide-header"> our orders</h2></div>
                <div>
                    <h5 class="slide-h5">Home</h5>
                    <b-icon :icon="isRTL ? 'chevron-double-left' : 'chevron-double-right'" class="icon-slider"></b-icon>
                    <h5 class="slide-h5"> Orders</h5>
                </div>
            </div>
            </div>
        </div>
        <div v-if="Object.keys(this.order).length" class="container custom-container-orders mt-5">
            <b-button variant="link" class="back-btn" @click="$router.go(-1)"> <b-icon
                    :icon="isRTL ? 'chevron-right' : 'chevron-left'" class="icon-slider"></b-icon>Back</b-button>
            <b-tabs pills card>
                <b-tab title="Order Details" active>
                    <div>
                        <!-- Order Details content here -->
                        <b-row>
                            
                                <b-col col="12">
                                <div class="equipments-header">
                                    <p><strong>Order Number</strong></p>
                                    <p> {{this.order.id}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Order Time</strong></p>
                                    <p>{{this.order.site_arrival_time}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Company Name</strong></p>
                                    <p>{{this.order.customer_name}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Admin Of The Order</strong></p>
                                    <p> {{this.order.adminName}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Client Name</strong></p>
                                    <p> {{this.order.customer_name}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Phone Number</strong></p>
                                    <p> {{this.order.customer_phone}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Address</strong></p>
                                    <p style="margin-left: 45px;"><a :href="googleMapsLink(this.order.customerMapUrl)" target="_blank">{{ this.order.customerMapUrl }}</a></p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Service</strong></p>
                                    <p> {{this.order.serviceName}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Technician</strong></p>
                                    <p> {{this.order.technicalLeaderName}}</p>
                                </div>
                            </b-col>
                            
                            
                        </b-row>
                    </div>
                    <hr style="background-color: #C0DBFF;height: 2px;">
                    <div>
                        <b-row>
                            <b-col col="12">
                                <div class="equipments-header">
                                    <p><strong>Status</strong></p>
                                    <p>{{this.order.order_status}}</p>
                                </div>
                            </b-col>
                            
                        </b-row>
                    </div>
                </b-tab>

                <b-tab title="The Equipments">
                    <div class="mb-5">
                        <b-row>
                            <b-col col="12">
                                <div class="equipments-header">
                                    <p><strong>Equipment Repalced</strong></p>
                                    <p> {{this.order.equipment_replaced}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Equipment Repalced s Available</strong></p>
                                    <p>{{this.order.sub_equipment_replaced}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Error Type</strong></p>
                                    <p>{{this.order.error_type}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Activity Domains</strong></p>
                                    <p> {{this.order.activity_domain}}</p>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="mb-5">
                        <b-table
                        :items="items"
                        :fields="fields"
                        responsive="sm"
                        hover 
                        striped 
                        bordered
                        >

                        <template #thead-top="data">
                            <b-tr>
                            <b-th style="width: 20%;"colspan="1"><span class="sr-only">Status</span></b-th>
                            <b-th class="repalced-equipment" style="width: 40%;">Repalced Equipment</b-th>
                            <b-th class="spare-parts"style="width: 40%;" colspan="3">Spare Parts</b-th>
                            <!-- <b-th variant="danger">Type 3</b-th> -->
                            </b-tr>
                        </template>
                        </b-table>
                    </div>
                    <div class="mb-5">
                        <b-row>
                            <b-col col="12">
                                <div class="equipments-header">
                                    <p><strong>Sub Equipment Replaced</strong></p>
                                    <p> {{this.order.equipment_replaced}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Sub Equipment Replaced s Available</strong></p>
                                    <p>{{this.order.sub_equipment_replaced}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Error Type</strong></p>
                                    <p>{{this.order.error_type}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Activity Domain</strong></p>
                                    <p> {{this.order.activity_domain}}</p>
                                </div>
                            </b-col>
                        </b-row>
                    </div>


                    <div class="mb-5">
                        <b-table
                        :items="items"
                        :fields="fields"
                        responsive="sm"
                        hover 
                        striped 
                        bordered
                        >

                        <template #thead-top="data">
                            <b-tr>
                            <b-th style="width: 20%;"colspan="1"><span class="sr-only">Status</span></b-th>
                            <b-th class="repalced-equipment" style="width: 40%;">Replaced Sub Equipment</b-th>
                            <b-th class="spare-parts"style="width: 40%;" colspan="3">Spare Parts</b-th>
                            <!-- <b-th variant="danger">Type 3</b-th> -->
                            </b-tr>
                        </template>
                        </b-table>
                    </div>
                </b-tab>

                <b-tab title="The Time">
                    <div>
                        <div class="">
                            <b-row>
                                <b-col col="12">
                                    <div class="equipments-header">
                                        <p><strong>Site Arrival Date</strong></p>
                                        <p> {{this.order.site_arrival_date}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>Site Arrival Time</strong></p>
                                        <p>{{this.order.site_arrival_time}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>Repair Start Date</strong></p>
                                        <p>{{this.order.repair_start_date}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>Repair End Date</strong></p>
                                        <p> {{this.order.repair_end_date}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>Repair End Time</strong></p>
                                        <p> {{this.order.repair_end_time}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>Start Location Latitude</strong></p>
                                        <p> {{this.order.start_latitude}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>Start Location Longitude</strong></p>
                                        <p> {{this.order.start_longitude}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>End Location Latitude</strong></p>
                                        <p> {{this.order.end_latitude}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>End ocation Longitude</strong></p>
                                        <p> {{this.order.end_longitude}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>Site Departure Date</strong></p>
                                        <p> {{this.order.site_departure_date}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>Site Departure Time</strong></p>
                                        <p> {{this.order.site_departure_time}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>Ttf</strong></p>
                                        <p> {{this.order.ttf}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>Tta</strong></p>
                                        <p> {{this.order.tta}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>Ttr</strong></p>
                                        <p> {{this.order.ttr}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>Tbf</strong></p>
                                        <p> {{this.order.tbf}}</p>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>

                    </div>
                </b-tab>

                <b-tab title="The Cost">
                    <div>
                        <div>
                        <b-row>
                            <b-col col="12">
                                <div class="equipments-header">
                                    <p><strong>Replaced Spare Part Cost</strong></p>
                                    <!-- <p> {{this.order.equipment_replaced}}</p> -->
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Labour Cost</strong></p>
                                    <!-- <p>{{this.order.sub_equipment_replaced}}</p> -->
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Transported By</strong></p>
                                    <p>{{this.order.transportationName}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Transportation Cost</strong></p>
                                    <p> {{this.order.transportation_cost}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>Toll Gate Cost</strong></p>
                                    <p> {{this.order.toll_gate_cost}}</p>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
        <div v-else>
            <div class="text-center p-4" style="width: 100%;">
                <img :src= "require('../assets/not-found.svg')" alt="404 Photo" style="width:500px;">
                <p class="mb-0 filter-result"> {{ $t('message.noMatchingResults') }} "{{ id }}".</p>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../axios-config';

export default {
    name: 'ShowDetails',
    props: ['id'], // التأكد من أن الخاصية id تم تمريرها بشكل صحيح
    data() {
        return {
            order:{},
            items: [
                { Status: 'Family Code', Used: 1,   New: 542 },
                { Status: 'Equip Family', Used: 2,  New: 'Fuel Tank & Pipework' },
                { Status: 'Type Code', Used: 3,  New: 'Fuel Tank & Pipework' },
                { Status: 'Equip Type', Used: 4, New: 'Fuel Tank & Pipework' },
                { Status: 'Equip Serial', Used: 5,  New: 'Fuel Tank & Pipework' },
                { Status: 'Equip Model', Used: 5,  New: 'Fuel Tank' },
                { Status: 'Equip Manufacturer', Used: 5,  New: 'Fuel Tank' },
                { Status: 'Cost', Used: 5,  New: 'Fuel Tank' },
            ],
            fields: [
                'Status',
                { key: 'Used', label: 'Used' },
                { key: 'New', label: 'New' }
            ]
        };

    },

    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        googleMapsLink(location) {
            const encodedLocation = encodeURIComponent(location);
            return `https://www.google.com/maps/search/?api=1&query=${encodedLocation}`;
        },

        async fetchData() {
            try {
                const formData = new FormData();
                formData.append('order_id', this.id);
                const response = await axiosInstance.post('company/select_order_by_id.php', formData);

                console.log(response.data);
                if (response.data.status === "true") {
                    this.order = response.data.output;
                    console.log('order = ', this.order);

                }
            } catch (error) {
                console.error('Error logging in:', error)
                this.$bvToast.toast('An error occurred while trying to get data', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-left', // Position of the toast
                    // autoHideDelay: 1000,
                });
            }
        },
        showDetails(itemId){
            console.log(111111111);
            // this.$router.push('show-details/' + itemId);
            this.$router.push(`/orders/${itemId}/details`);
            // /orders/:id/details
        }
    }

};
</script>

<style scoped>
.custom-container-orders {
    /* Custom styles for the container */
}

.back-btn {
    color: #003A6E;
    font-family: 'Somar-Bold';
    font-size: 28px;
    display: flex;
    align-items: center;
}

.back-btn:hover {
    text-decoration: none;
    color: #003A6E;
}

.icon-slider {
    font-size: 15px !important;
    color: #FFD448 !important;
    margin-top: 0px !important;
}

.detais-header p strong {
    color: #2B5FA8;
    font-family: 'Somar-Bold';
    font-size: 25px;
}
.equipments-header p strong {
    color: #2B5FA8;
    font-family: 'Somar-Bold';
    font-size: 25px;
}

.detais-response p {
    color: #7F98BB;
    font-family: 'Somar-Medium';
    font-size: 25px;
}
.equipments-header p {
    color: #7F98BB;
    font-family: 'Somar-Medium';
    font-size: 25px;
}
p{
    /* margin-bottom: 5px; */
}
.equipments-header{
    display: flex;
    justify-content: space-between;
}
.repalced-equipment , .spare-parts{
    color: #fff;
    font-family: 'Somar-Bold';
    font-size: 22px;
    text-align: center
}
.repalced-equipment{
    background-color:#5B9BF5;
}
.spare-parts{
    background-color:#2B5FA8;
}
</style>

