<template>
  <b-navbar toggleable="lg" type="dark" class="py-3 navbar fixed-top" fixed="top" >
    <!-- Navbar Brand -->
    <!-- <b-navbar-brand href="#">LOGO</b-navbar-brand> -->

    <!-- Navbar Toggle Button -->
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <!-- Language and User Role Dropdown (visible on mobile next to the toggle) -->
    <div class="d-lg-none language-userRole">
      <b-nav-item-dropdown right>
        <template #button-content>
          <img class="avatar avatar-xss avatar-circle"
               :src="currentLanguage === 'ar' ? require('../assets/ar.svg') : require('../assets/us.svg')"
               :alt="currentLanguage === 'ar' ? 'Saudi Arabia flag' : 'US flag'">
        </template>
        <b-dropdown-item @click="switchLanguage('ar')">
          <img :src="require('../assets/ar.svg')" width="30" height="30" alt="Arabic" class="avatar-circle"> العربية
        </b-dropdown-item>
        <b-dropdown-item @click="switchLanguage('en')">
          <img :src="require('../assets/us.svg')" width="30" height="30" alt="English" class="avatar-circle"> English
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown right>
        <template #button-content>
          <span class="user-role">App Manager</span> 
          <img :src="require('@/assets/Group 21188.png')" width="30" height="30" alt="userRole" class="">
        </template>
        <b-dropdown-item href="#">Profile</b-dropdown-item>
        <b-dropdown-item href="#">Settings</b-dropdown-item>
        <b-dropdown-item v-if="isAuthenticated" @click="logout">Logout</b-dropdown-item>
      </b-nav-item-dropdown>
    </div>

    <!-- Navbar Collapse -->
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item>
          <router-link to="/" exact>Home</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/about-us">About</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/orders">Orders</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/Requests">Requests</router-link>
        </b-nav-item>
        <!-- <b-nav-item disabled>
          <router-link to="/Companies">Companies</router-link>
        </b-nav-item> -->
        <b-nav-item>
          <router-link to="/Employees">Employees</router-link>
        </b-nav-item>
        <b-nav-item disabled>
          <router-link to="/locations">Locations</router-link>
        </b-nav-item>
      </b-navbar-nav>

      <!-- Language and User Role Dropdown (visible on larger screens) -->
      <b-navbar-nav :class="[{ 'mr-auto': isRTL, 'ml-auto': !isRTL }]" class="d-none d-lg-flex">
        <b-nav-item-dropdown style="margin-top: 7px;" right>
          <template #button-content>
            <img class="avatar avatar-xss avatar-circle"
                 :src="currentLanguage === 'ar' ? require('../assets/ar.svg') : require('../assets/us.svg')"
                 :alt="currentLanguage === 'ar' ? 'Saudi Arabia flag' : 'US flag'">
          </template>
          <b-dropdown-item @click="switchLanguage('ar')">
            <img :src="require('../assets/ar.svg')" width="30" height="30" alt="Arabic" class="avatar-circle"> العربية
          </b-dropdown-item>
          <b-dropdown-item @click="switchLanguage('en')">
            <img :src="require('../assets/us.svg')" width="30" height="30" alt="English" class="avatar-circle"> English
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right>
          <template #button-content>
            <span class="user-role">App Manager</span> 
            <img :src="require('@/assets/Group 21188.png')" width="30" height="30" alt="userRole" class="">
          </template>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
        <b-dropdown-item href="#">Settings</b-dropdown-item>
        <b-dropdown-item v-if="isAuthenticated" @click="logout">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "CustomNavbar",
  data(){
    return{
      currentLanguage: this.$i18n.locale,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    isRTL() {
      return this.$i18n.locale === 'ar';
    }
  },
  methods: {
    // في component الخاص بـ navbar
    ...mapActions(['logout']),
    async logout() {
      try {
        await this.$store.dispatch('logout'); // حذف بيانات المستخدم من Vuex
        // مسح userId من localStorage
        localStorage.removeItem('userId');
        
        this.$bvToast.toast('You have successfully logged out', {
          title: 'successfully logged out',
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-bottom-left',
          headerClass:this.toastClass,
          bodyClass: this.toastClass,
          // autoHideDelay: 1000,
        });
        setTimeout(() => {
          this.$router.push('/login'); // التحويل إلى صفحة تسجيل الدخول
        }, 2000);
      } catch (error) {
        console.error('Error logging out:', error);
      }
    },

    handleClick(){
      this.$router.push('/Companies');
    },
    switchLanguage(language) {
      this.currentLanguage = language;
      this.$i18n.locale = language;
    }
  }
};
</script>

<style scoped>
.navbar {
  background-color: #2274BD; /* لون الخلفية الأزرق */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.5rem;
}
/* 
.nav-item.active {
  background-color: #0056b3; 
  color: #FFD448 !important;
} */

.navbar-nav .router-link-active {
  color: #FFD448 !important;
  border: none;
  outline: none;
  text-decoration: none;
}
.navbar-nav a {
  color: #FFFFFF;
  font-size: 22px;
}
.user-role{
  font-size: 22px;
  padding-left: 5px;
  padding-right: 5px;
}
.navbar-nav a:hover {
  text-decoration: none;
  border: none;
  outline: none;
  color: #FFD448 !important;
}


.nav-link {
  color: #fff;
  /* font-weight: bold; */
  /* font-family: somar; */
  font-size: medium;
}

.dropdown-menu {
  background-color: #0062cc; /* لون خلفية القائمة المنسدلة */
  color: #fff;
}

.dropdown-item {
  color: #fff;
}

.dropdown-item:hover {
  background-color: #0056b3; /* لون الخلفية عند التمرير */
}
.avatar-circle{
  border-radius: 50%;
    width: 1.3rem;
    height: 1.3rem;
}
.avatar{
  margin-top: -3px;
}
.language-userRole{
  display: flex;
  align-items: center;
    
}
.language-userRole li{
  list-style: none;
}
</style>
