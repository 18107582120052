<template>
    <div>
        <div class="slide">
            <div class="bg-img">
                <div class="slide-content">
                    <div><h2 class="slide-header"> our orders</h2></div>
                <div>
                    <h5 class="slide-h5">Home</h5>
                    <b-icon :icon="isRTL ? 'chevron-double-left' : 'chevron-double-right'" class="icon-slider"></b-icon>
                    <h5 class="slide-h5"> Orders</h5>
                </div>
            </div>
            </div>
        </div>
        <div class="container custom-container-orders mt-5">
            <b-row class="align-items-center">
                <b-col cols="6" class="d-flex align-items-center">
                    <h3 class="filter-text">Filter</h3>
                    <b-form-input class="filter-input" id="filter-input" v-model="filter"
                        placeholder="Admin Name"></b-form-input>
                </b-col>
                <b-col cols="6" class="text-right">
                    <b-button variant="warning" @click="newOrder()" class="new-order">Add new order</b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col v-for="order in filteredRequests" :key="order.id" cols="12" md="6" class="mb-3 mt-5">
                    <b-card class="request-card">
                        <b-card-text>
                            <div class="request-info-row">
                                <span><strong>Order Number</strong>
                                    <div class="request-response"><span> {{ order.id }} </span></div>
                                </span>
                                <span class="date-time"><b-icon class="clock-icon" icon="clock"
                                        aria-hidden="true"></b-icon>{{ order.site_arrival_date }} {{
                        order.site_arrival_time }}</span>
                            </div>
                            <div style="">
                                <p class="request-info"><strong>Added By</strong>
                                <div class="request-response"> <span> {{ order.adminName }}</span></div>
                                </p>
                            </div>
                            <div style="">
                                <p class="request-info"><strong>Service</strong>
                                <div class="request-response"><span> {{ order.serviceName }} </span></div>
                                </p>
                            </div>
                            <div style="">
                                <p class="request-info"><strong>status</strong>
                                <div class="request-response"><span> {{ order.order_status }} </span></div>
                                </p>
                            </div>
                        </b-card-text>
                        <template #footer>
                            <!-- <b-button variant="info">Show Details</b-button> -->
                            <p @click="showDetails(order.id)">Show Details</p>
                        </template>
                    </b-card>
                </b-col>
                <div class="mt-5">
                    <b-pagination v-model="currentPage" pills :total-rows="rows" :per-page="perPage"></b-pagination>
                </div>
            </b-row>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../axios-config';
import { mapGetters } from 'vuex'

export default {
    name: "about",
    components: {
        //   slider,
    },
    data() {
        return {
            filter: '',
            orders: [],
            rows: 0,
            currentPage: 1,
            perPage: 10,
        };

    },
    computed: {
        ...mapGetters(['getUser']),
        company_id() {
            return this.getUser; // استخدام الـ getter لجلب بيانات المستخدم
        },
        filteredRequests() {
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            const filtered = this.orders.filter(order =>
                order.adminName.toLowerCase().includes(this.filter.toLowerCase())
            );
            this.rows = filtered.length; // تحديث إجمالي الصفوف بعد التصفية
            return filtered.slice(start, end);
        },
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
    },
    created() {
        this.fetchData();
    },
    methods: {

        async fetchData() {
            try {
                const formData = new FormData();
                // formData.append('current_date', '2023-12-14');
                formData.append('offset', 0);
                formData.append('company_id', this.company_id.id);
                const response = await axiosInstance.post('company/select_orders.php', formData);

                console.log(response.data);
                if (response.data.status === "true") {
                    this.orders = response.data.output;
                    console.log('orders = ', this.orders);

                }
            } catch (error) {
                console.error('Error logging in:', error)
                this.$bvToast.toast('An error occurred while trying to get data', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-left', // Position of the toast
                    // autoHideDelay: 1000,
                });
            }
        },
        showDetails(itemId){
            console.log(111111111);
            // this.$router.push('show-details/' + itemId);
            this.$router.push(`/orders/${itemId}/details`);
            // /orders/:id/details
        },
        newOrder(){
            this.$nextTick(() => {
                this.$router.push('/addNewOrder');
            });
        }
    }
}

</script>

<style scoped>
.row {
    justify-content: center;
}

.request-card {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.b-pagination-pills .page-item .page-link {
    line-height: 0.8;
}

.request-info {
    margin-bottom: 0px !important;
}

p,
.request-info-row {
    margin-bottom: 0px !important;
}

.request-info-row strong {
    margin-right: 38px !important;
}

.card-footer p {
    margin-bottom: 0px !important;
    font-family: 'Somar-Bold';
    font-size: 21px;
}

.date-time {
    color: #777D85 !important;
}

.card-footer:hover {
    background-color: #3C89CE !important;
    transition: all 0.3s ease-in-out !important;
    cursor: pointer;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.card-footer:hover p {
    color: #fff !important;
    transition: all 0.3s ease-in-out !important;
}
.new-order{
    background-image: linear-gradient(90deg, #FFCA1F 0%, #FFD44A 100%);
    border-radius: 20px;
    color: #fff;
    width: 200px;
    font-size: 21px;
    font-family: 'Somar-bold';
    border-width: 0px;
}
.new-order:hover{
    outline: none;
    border-width: 0px;
    background-image: linear-gradient(90deg, #FFD44A 0%, #FFCA1F 100%);
    transition: all 0.3s ease-in-out;
    color: #fff;
}

</style>