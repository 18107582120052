<template>
    <section class="hero-section text-center py-5">
      <b-container>
        <b-row>
          <b-col cols="6" class="hero-item">
            <div class="hero-content">
                <h1 class="hero-header">WITH OUR COMMUNITY</h1>
                <p class="lead">What About Of Reviewing The Today’s <span style="display: block;"> Requests? </span></p>
                <b-button variant="warning" class="mt-3 hero-button">Check Request</b-button>
            </div>
          </b-col>
          <b-col cols="6">
            <img :src="require('@/assets/home-slider.svg')" alt="Hero Image" class="img-fluid hero-image">
          </b-col>
        </b-row>
    
      </b-container>
    </section>
  </template>
  
  <script>
  export default {
    name: "HeroSection"
  };
  </script>
  
  <style scoped>
  .hero-section {
    /* background-color: #f5f5f5; */
  }
  .hero-image{
    width: 100%;
    /* height: 350px; */
  }
  .hero-content{
    /* margin-top: 20px; */
    text-align: left;
  }
  .hero-item{
    align-items: center;
    display: flex;
  }
  .hero-header{
    font-size: 2.9rem; /* ضبط حجم الخط حسب احتياجك */
  background-image: linear-gradient(90deg, #003A6E 0%, #3A7BD3 54%, #3B7BD4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  }
  .lead{
    color: #92A2AF;
    font-size: 30px;
    font-family: 'Somar-Medium';
  }
  .hero-button{
    background-image: linear-gradient(90deg, #FFCA1F 0%, #FFD44A 100%);
    border-radius: 50px;
    width: 200px;
    color: #fff;
    font-size: 25px;
    font-family: 'Somar-bold';
    border-width: 0px;
  }
  .hero-button:hover{
    outline: none;
    border-width: 0px;
    background-image: linear-gradient(90deg,#FFD44A 0% , #FFCA1F 100%);
    transition: all 0.3s ease-in-out;
    color: #fff;
  }
  </style>
  