<template>
  <div class="footer-container">

    <b-row class="footer-content text-white py-4">
      <b-col md="4" sm="12" class="mb-3">
        <h4 class="footer-logo"><img :src="require('../assets/logo-medium.png')" width="50" height="50" /></h4>
        <p class="footer-paragraph">Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Tempor Incididunt Ut Labore</p>
      </b-col>
      <b-col md="4" sm="12" class="mb-3">
        <h5>Important Links</h5>
        <ul class="list-unstyled important-content" :class="[{ 'list-unstyled-rtl': isRTL, 'list-unstyled-ltr': !isRTL }]">
          <li>
            <b-link href="#">
              <b-icon 
                :icon="isRTL ? 'chevron-left' : 'chevron-right'" 
                class="important-links"
              ></b-icon>
              <span>Orders</span>
            </b-link>
          </li>
          <li>
            <b-link href="#">
              <b-icon 
                :icon="isRTL ? 'chevron-left' : 'chevron-right'" 
                class="important-links"
              ></b-icon>
              <span>Employees</span>
            </b-link>
          </li>
          <li>
            <b-link href="#">
              <b-icon 
                :icon="isRTL ? 'chevron-left' : 'chevron-right'" 
                class="important-links"
              ></b-icon>
              <span>Locations</span>
            </b-link>
          </li>
          <li>
            <b-link href="#">
              <b-icon 
                :icon="isRTL ? 'chevron-left' : 'chevron-right'" 
                class="important-links"
              ></b-icon>
              <span>Companies</span>
            </b-link>
          </li>
        </ul>


      </b-col>
      <b-col md="4" sm="12" class="mb-3">
        <h5 class="contact-header">Contact Us</h5>
        
        <div class="social-icons">
          <b-link href="#"><b-icon icon="facebook" variant="light"></b-icon></b-link>
          <b-link href="#"><b-icon icon="linkedin" variant="light"></b-icon></b-link>
          <b-link href="#"><b-icon icon="instagram" variant="light"></b-icon></b-link>
          <b-link href="#"><b-icon icon="twitter" variant="light"></b-icon></b-link>
        </div>
        <div class="contact-tele">
          <b-icon icon="telephone" class="icon-phone" :class="[{ 'icon-phone-rtl': isRTL, 'icon-phone-ltr': !isRTL }]" variant="light"></b-icon> <p class="contact-phone"> +201500699121 </p>
        </div>
      </b-col>
    </b-row>
    <hr style="border-width: 2px;">
    <!-- حقوق الملكية -->
    <b-row class="footer-bottom text-center py-2">
      <b-col>
        <p> All Rights Reserved  <span> © </span> Powered by EverOn </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'theFooter',
  data(){
    return{

    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale === 'ar';
    }
  },
};
</script>

<style scoped>
.footer-container {
  background-image: url(../assets/Group\ 21185.png);
  background-size: cover;
  position: relative;
  color: #fff;
}

.footer-content {
 
  padding-top: 150px !important;
  
  padding-left: 50px; 
  padding-right: 50px;  
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px !important; 
    margin-left: 0px !important;
}

.footer-logo {
  font-size: 24px;
  font-weight: bold;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #FFFFFF;
}
.footer-paragraph{
  color: #D1E4FF;
}

.social-icons a {
  margin: 2px; 
}
.social-icons a {
  width: 40px; /* حجم الأيقونة */
  height: 40px;
  line-height: 40px; /* لضمان أن الأيقونة تكون في المنتصف عمودياً */
  background-color: #3C89CE; /* اللون الداخلي للدائرة */
  border-radius: 50%; /* لجعل الأيقونات دائرية */
  text-align: center; /* لضمان أن الأيقونة تكون في المنتصف أفقياً */
  display: inline-flex; /* استخدام flexbox لجعل الأيقونة في المنتصف */
  justify-content: center;
  align-items: center;
  color: #fff; /* اللون الافتراضي للأيقونات */
}
.social-icons a:hover{
  background-color: #FFD448;
  transition: all ease-in 0.2s;
}
.important-content li a{
  color: #E9F0FF !important;
  font-size: medium;
  text-decoration: none;
  padding: 2px;
}
.important-links{
  color:  #FFD448 !important;
  font-size: small;
  margin: 2px;
}
.important-content li a span{
  font-family: 'Somar-Medium';
  font-size: 20px ;
}
.footer-bottom p {
  font-family: 'Somar-Medium';
  font-size: 20px ;
}
.footer-bottom span {
  color:  #FFD448 !important;
}
.footer-paragraph{
  width: 210px;
  font-family: 'Somar-Regular';
  color: #D1E4FF;
  font-size: 20px;
}
h5{
  font-family: 'Somar-Bold';
  font-size: 24px;
}
.list-unstyled-ltr{
  padding-left: 0px !important;
}
.list-unstyled-rtl{
  padding-right: 0px !important;
}

.contact-tele{
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}

.contact-phone{
  font-size: 22px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.contact-tele:hover .icon-phone , .contact-tele:hover{
  color:  #FFD448 !important;
  transition: all 0.3s ease-in-out;
}
.icon-phone{
  margin-top: -12px;
  
}
.icon-phone-rtl{
  margin-left: 10px;
}
.icon-phone-ltr{
  margin-right: 10px;
}


@media (max-width: 576px) {
.footer-content {
 padding-top: 150px !important;
 padding-left: 30px; 
 padding-right: 30px;  
}
}
</style>
